<template>
  <div class="user-center">
    <div class="banner">
      <div class="banner-bg"></div>
      <div class="banner-bottom"></div>
      <div class="back" @click="goBack()"></div>
      <div class="user-info">
        <img v-if="userInfo && userInfo.avatar!=''" class="user-img" :src="userInfo.avatar">
        <img v-else class="user-img" src="~@/assets/head-default-personal.png">
        <div class="user-content">
          <span class="name">{{userInfo.name!=''&&userInfo ? userInfo.name : userInfo.phone}}</span>
          <div class="user-company" v-if="userInfo.company_name">{{userInfo.company_name}}</div>
        </div>
        <!-- <div class="arrows"></div> -->
      </div>
      <div class="area">
        <router-link class="area-info" :to="'/ldgxxyUserCenter/myClass'">
          <img class="area-img" src="~@/assets/class-ico.png">
          <p class="area-title">我的班级</p>
        </router-link>
        <router-link class="area-info" :to="'/ldgxxyUserCenter/certificate'">
          <img class="area-img" src="~@/assets/collect-pink-ico.png">
          <p class="area-title">领取证书</p>
        </router-link>
        <router-link class="area-info" :to="'/ldgxxyUserCenter/learning'">
          <img class="area-img" src="~@/assets/book-ico.png">
          <p class="area-title">学习记录</p>
        </router-link>
      </div>
    </div>
    <!-- <div class="menu">
       <router-link v-if="userInfo.did !=55"  class="menu-info buy" :to="'/userCenter/purchaseRecords'">购买记录</router-link>
       <router-link class="menu-info change-pass" :to="'/userCenter/changePassword'">修改密码</router-link>
       <router-link class="menu-info buy" :to="'/userCenter/commonExam'">我的公开考试</router-link>
    </div> -->
    <!-- <button class="log-out" @click="exitBtn">退出</button> -->
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: 'UserCenter',
  components: {
  },
  data(){
    return {
      // userInfo:'',
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    
    this.GetUserInfo(); //获取用户信息
    if(!this.userInfo){
      this.ShowMLoginModal()
      return
    }
  },
  methods: {
    ...mapMutations(['GetUserInfo', 'ClearUserInfo', '','ShowMLoginModal']), //tip弹窗
    //退出按钮
    exitBtn(){
      // 贵人家园
      this.$axios
        .post(`/v1/gzLoginLogout`, {
          ssologinid: localStorage.getItem('ssologinid')
        })
        .then((res) => {
          if (res.code == 0) {
            this.$router.replace('/')
          }
        });
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
      localStorage.removeItem('userInfo');
      this.ClearUserInfo();
      
      if(this.$route.path.includes('userCenter')){
        this.$router.push('/index');
      }else{
        //this.$router.go(0);
        location.reload();
      }
    },
    goBack(){
      window.location.href = 'http://culrcx.kjcxchina.com/#/index';
    },
  }
}
</script>

<style lang="scss" scoped>
.user-center{
  min-height: 100vh;
  position: relative;
}
.banner{
  width: 100%;
  height: 500px;
  background: url(~@/assets/top-bg.png) no-repeat -140px top;
  background-size: auto 260px;
  position: relative;
  .banner-bg{
    width: 100%;
    height: 376px;
    background: url(~@/assets/top-bg.png) no-repeat -140px top;
    background-size: auto 260px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .banner-bottom{
    width: 750px;
    height: 260px;
    background: linear-gradient(0deg, rgba(3, 16, 61, 0) 0%, rgba(3, 16, 61, 0.12) 17%, rgba(3, 16, 61, 0.28) 28%, rgba(3, 16, 61, 0.46) 37%, rgba(3, 16, 61, 0.69) 55%, rgba(3, 16, 61, 0.86) 70%, rgba(3, 16, 61, 0.96) 83%, #03103D 100%, #03103D 100%);
    position: absolute;
    top: 240px;
    left: 0;
    z-index: 0;
  }
  .back{
    width: 88px;
    height: 88px;
    background: url(~@/assets/arrows-white.png) center center no-repeat;
    background-size: auto 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .user-info{
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    overflow: hidden;
    position: absolute;
    top: 120px;
    z-index: 3;
  }
  .arrows{
    width: 88px;
    height: 88px;
    background: url(~@/assets/arrows-white.png) center center no-repeat;
    background-size: auto 30px;
    transform:rotate(180deg);
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -44px;
    z-index: 3;
  }
  .user-img{
    width: 120px;
    height: 120px;
    border: 3px solid #CCC;
    border-radius: 50%;
    float: left;
  }
  .user-content{
    width: 500px;
    float: left;
    margin-left: 24px;
    color: #FFF;
    font-size: 32px;
    .name{
      padding-top: 10px;
    }
    .user-company{
      font-size: 22px;
      padding-top: 24px;
    }
  }
}
.area{
  box-sizing: border-box;
  width: 694px;
  padding: 34px 80px 28px;
  background: #FFF;
  box-shadow: 0px 1px 8px 0px rgba(8, 20, 60, 0.14);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 310px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  .area-img{
    display: block;
    width: auto;
    height: 70px;
    margin: 0 auto;
  }
  .area-title{
    padding-top: 7px;
    font-size: 26px;
    color: #666;
    text-align: center;
  }
}
.menu{
  padding: 40px 28px;
  .menu-info{
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 88px;
    padding-left: 68px;
    line-height: 88px;
    font-size: 28px;
    color: #333;
    position: relative;
    &.buy{
      background: url(~@/assets/pay-blue-icoico.png) 8px center no-repeat;
      background-size: auto 44px;
    }
    &.change-pass{
      background: url(~@/assets/lock-blue-ico.png) 10px center no-repeat;
      background-size: auto 44px;
    }
    &::after{
      content: '';
      display: block;
      width: 34px;
      height: 34px;
      background: url(~@/assets/arrows.png) 0 center no-repeat;
      background-size: 100% auto;
      transform:rotate(-90deg);
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -17px;
    }
  }
}
.log-out{
  display: block;
  width: 654px;
  height: 88px;
  background: #254ED4;
  border-radius: 6px;
  font-size: 28px;
  color: #FFF;
  margin: 0 auto;
  position: absolute;
  bottom: 88px;
  left: 0;
  right: 0;
}
</style>
